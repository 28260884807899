<template>
  <div>
    <LocationTroubleshoot v-if="mapHasRendered"/>
    <v-overlay :value="parcelLoading">
      <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div id="map" class="map">
      <div class="mapboxgl-control-container">
        <div class="mapboxgl-ctrl-top-left">
          <div>
            <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
              <v-menu
                offset-x
                :close-on-content-click="false"
                v-model="dialog.layer"
                transition="slide-x-transition"
              >
                <template #activator="{ on }">
                  <button class="mapboxgl-ctrl-fullscreen" v-on="on">
                    <v-icon size="20" style="margin-top: -3px" color="black">mdi-layers</v-icon>
                  </button>
                </template>
                <map-setting :show="mapSettingFields" :linkedGroups="linkedGroupsLabels">
                  <template #title>
                    Layers
                    <v-spacer></v-spacer>
                    <v-btn @click="dialog.layer = false" icon>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                </map-setting>
              </v-menu>
              <v-menu offset-x transition="slide-x-transition">
                <template #activator="{ on }">
                  <button class="mapboxgl-ctrl-fullscreen" v-on="on">
                    <v-icon size="20" style="margin-top: -3px" color="black">mdi-map</v-icon>
                  </button>
                </template>
                <v-card>
                  <div class="d-flex flex-wrap border-a">
                    <div v-for="style in mapStyles" :key="style.value" class="pa-2">
                      <div>
                        <img
                          :src="`/map-layers/${style.value}.png`"
                          @click="mapStyle = style.style"
                          class="hover-image map-style-thumbnail"
                          :class="mapStyle === style.style ? 'img-border' : ''"
                        />
                        <div
                          class="text-center thumbnail-text"
                          style="margin-top: -2px; font-size: 14px"
                        >
                          {{ style.text }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
                </v-menu>
                <button class="mapboxgl-ctrl-fullscreen" v-if="$store.state.dialogs.locationPermissionTroubleshootButton">
                  <v-icon size="20" style="margin-top: -3px" color="error">mdi-map-marker-question</v-icon>
                </button>
            </div>
            <div class="mapboxgl-ctrl mapboxgl-ctrl-group" v-if="mapShowMore">
              <button class="mapboxgl-ctrl-fullscreen" @click="geoscriptDialog = !geoscriptDialog">
                <v-icon size="20" style="margin-top: -3px" color="black"> mdi-segment </v-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MapInfo
      ref="mapInfo"
      :cursor.sync="mapData.cursor"
      :value="mapInfo"
      :show-more.sync="mapShowMore"
    >
    </MapInfo>
    <MapGeoscript
      v-model="geoscriptDialog"
      :visible-layers="geoscripts"
      :visible-geometries="styledGeometries"
      :show="geoscriptDialog"
      @execute="executeGeoscript"
      @cancel="cancelGeoscript"
      @toggleGeometry="toggleGeometry"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

import Map from '../../services/map';
import config from '../../config';
import {
  geocircleToGeojson,
  getDescription,
  isSatellite,
  labelToGeostyle,
  lngLatFormatter,
  MAP_STYLES,
  parcelGeoJSON,
  parseGeoJSON,
  parseGeoPoint
} from '../../utils/map';
import {
  fmtParcelNumber,
  getNearbyParcels,
  getAllTotParcels,
  parcelCenter,
  parseAcres,
  searchParcel
} from '../../utils/parcel';
import truroBoundary from './truroBoundary';
import { CMS } from '../../services/cms';
import { geoscriptMapKeys, parseLayers, parseVariable } from '../../utils/parser';
import accountMixin from '../../mixins/account';
import parcelsComputedMixin from '../../mixins/parcelsComputed';
import bbox from '@turf/bbox';
import center from '@turf/center';
import { geostyleParcelPoint } from '../../utils/geostyles';
import { throttle } from 'throttle-debounce';
import LinkedGroup from '../../services/linkedGroup';

const getTextProperty = properties => {
  const keys = ['text', 'title', 'Title', 'Team'];
  for (const key of keys) {
    if (properties && properties[key]) {
      return properties[key];
    }
  }
  return '';
};

export default {
  mixins: [accountMixin, parcelsComputedMixin],
  components: {
    MapInfo: () => import('./MapInfo.vue'),
    MapSetting: () => import('./MapSettings.vue'),
    MapGeoscript: () => import('./Geoscript.vue'),
      LocationTroubleshoot: () => import("./LocationTroubleshoot.vue")
  },
  data() {
    return {
      mapData: {
        center: null,
        zoom: null,
        pitch: null,
        bearing: null,
        cursor: ''
      },
      mapInfo: {},
      dialog: {
        layer: false,
        geoscript: false
      },
      map: new Map(),
      mapSettingFields: [
        'reference',
        'tct',
        'tot',
        'acres',
        'scale',
        'boundary',
        'parcelId',
        'monitoringColor'
      ],
      mapShowMore: null,
      teamRendered: false,
      geoscripts: [],
      styledGeometries: [],
      loadedGeoscripts: {},
      loadedGeometries: {},
      mapState: {},
      mapMoving: false,
      mapHasRendered: false
    };
  },
  computed: {
    linkedGroups() {
      try {
        return this.$store.state.parcel.linkedGroups.filter(i => {
          if (i.admin && !this.$store.getters.isAdmin) {
            return false;
          }
          return !!i;
        });
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    linkedGroupsLabels() {
      return this.linkedGroups.map(i => i.value);
    },
    geoscriptDialog: {
      get() {
        return this.$store.state.parcel.geoscriptDialog || false;
      },
      set(value) {
        this.$store.commit('setGeoscriptDialog', value);
      }
    },
    teamGeoJSON() {
      const geojson = this.$store.state.parcel.teamGeoJSON;
      if (!geojson) {
        return {};
      }
      let boundary = geojson?.features.filter(feature => feature?.geometry?.type === 'LineString');
      let team = geojson?.features.filter(feature => feature?.geometry?.type === 'Polygon');
      boundary = {
        type: 'FeatureCollection',
        features: boundary || []
      };
      team = {
        type: 'FeatureCollection',
        features: team || []
      };
      return { boundary, team };
    },
    parcelFilters() {
      const opt = this.mapConfig;
      const loggedInType = this.$store.state.loggedInAs?.type;
      const res = {
        acresFilter: opt.acres,
        referenceFilter: opt.reference,
        mapStyle: opt.style,
        idFilter: opt.showParcelId,
        preserve: opt.landPreserve,
        showPreserve: opt.showPreserve,
        monitoringColor: opt.monitoringColor,
        land: opt.land
      };
      if (loggedInType === 'user') {
        res.monitoringColor = false;
      }
      return res;
    },
    mapStyle: {
      get() {
        return this.mapConfig.style;
      },
      set(value) {
        this.$store.commit('setMapConfig', { key: 'style', value });
        this.map.setStyle(value);
      }
    },
    mapStyles() {
      const MAP_STYLE_PREFIX = 'mapbox://styles/mapbox';
      const style = {
        streets: `${MAP_STYLE_PREFIX}/streets-v11`,
        satellite: `${MAP_STYLE_PREFIX}/satellite-v9`,
        light: `${MAP_STYLE_PREFIX}/light-v10`,
        outdoors: `${MAP_STYLE_PREFIX}/outdoors-v11`,
        satelliteStreet: `${MAP_STYLE_PREFIX}/satellite-streets-v11`
      };
      let styles = [];
      styles.push({
        text: 'Light',
        value: 'light',
        style: style.light
      });
      styles.push({
        text: 'Outdoor',
        value: 'outdoor',
        style: style.outdoors
      });
      styles.push({
        text: 'Street',
        value: 'street',
        style: style.streets
      });
      styles.push({
        text: 'Hybrid',
        value: 'satelliteStreet',
        style: style.satelliteStreet
      });
      styles.push({
        text: 'Satellite',
        value: 'satellite',
        style: style.satellite
      });
      return styles;
    },
    parcelLoading() {
      return this.$store.state.parcel.parcelLoading;
    },
    scaleControl() {
      return this.mapConfig.scale;
    },
    searchQuery() {
      return this.$store.state.parcel.searchQuery;
    }
  },
  created() {
    const getLocalstorage = (key, defaultValue = null) => {
      try {
        const value = JSON.parse(localStorage.getItem(key));
        if (!value) {
          throw new Error(`${key} does not exists on LocalStorage`);
        }
        return value;
      } catch {
        return defaultValue;
      }
    };

    const q = this.$route.query;

    const qZoom = Number(q.zoom || 0);
    const qPitch = Number(q.bearing || 0);
    const qBearing = Number(q.pitch || 0);

    this.mapData.center = getLocalstorage('mapCenter', [-70.068552, 42.029477]);
    this.mapData.zoom = qZoom || getLocalstorage('mapZoom', 11);
    this.mapData.pitch = qPitch || getLocalstorage('mapPitch', 0);
    this.mapData.bearing = qBearing || getLocalstorage('mapBearing', 0);
    this.mapData.style = this.mapConfig.style;

    if (this.$route.query.center) {
      let center = Array.from(this.$route.query.center.split(','));
      center = center.map(i => Number(i));
      if (center.length === 2 && center.every(c => typeof c === 'number' && !isNaN(c))) {
        this.mapData.center = center;
        if (!qZoom) {
          this.mapData.zoom = 16;
        }
      }
    }
  },
  mounted() {
    this.map = new Map({
      accessToken: config.mapboxToken,
      style: this.mapData.style,
      center: this.mapData.center || [0, 0],
      bearing: this.mapData.bearing,
      pitch: this.mapData.pitch,
      zoom: this.mapData.zoom,
      persistMapState: true,
    });
    this.map.onLoad(() => {
      this.onMapLoad();
      this.renderQueryLayers();
      if (localStorage.getItem('flyToUser')) {
        setTimeout(() => {
          this.map.controls.GeolocateControl.trigger();
        }, 500);
        localStorage.removeItem('flyToUser');
      }
    });
    this.$bus.$on('clickParcel', this.onClickParcel);
    this.$bus.$on('refreshGeoscripts', this.refreshGeoscripts);
    this.$bus.$on('renderSearchResults', this.renderSearchResults);
    this.$bus.$on('renderHoverParcel', this.renderHoverParcel);
  },

  beforeDestroy() {
    this.$bus.$off('clickParcel', this.onClickParcel);
    this.$bus.$off('refreshGeoscripts', this.refreshGeoscripts);
    this.$bus.$off('renderSearchResults', this.renderSearchResults);
    this.$bus.$off('renderHoverParcel', this.renderHoverParcel);
    this.map.destroy();
  },

  watch: {
    'mapConfig.boundary': {
      handler() {
        this.renderTeamGeoJSON();
      },
      deep: true
    },
    'mapConfig.style': {
      handler() {
        this.renderTeamGeoJSON(true);
      }
    },
    '$store.state.parcel.linkedGroupsGeometries': {
      handler() {
        this.renderLinkedGroup();
      }
    },
    'mapConfig.linkedGroupGeometry': {
      handler() {
        this.renderLinkedGroup();
      }
    },
    'mapConfig.linkedGroupGeometryLabel': {
      handler() {
        this.renderLinkedGroup();
      }
    },
    'mapConfig.tct': {
      handler: 'renderNearbyParcels'
    },
    'mapConfig.totMode': {
      handler: 'renderNearbyParcels'
    },
    'mapConfig.tot': {
      handler: 'renderNearbyParcels'
    },
    scaleControl(value) {
      if (value) {
        this.map.addControls(['ScaleControl'], 'bottom-right');
      } else {
        this.map.removeControls(['ScaleControl']);
      }
    },
    parcelsComputed() {
      this.renderParcels();
      this.loadNearbyParcels();
    },
    parcelFilters() {
      this.renderParcels();
      this.refreshGeoscripts();
    },
    'parcelFilters.acresFilter': {
      handler() {
        this.renderNearbyParcels(true);
      }
    },
    'parcelFilters.idFilter': {
      handler() {
        this.renderNearbyParcels(true);
      }
    }
  },

  methods: {
    setLngLat() {
      let lngLat = {
        lng: '',
        lat: ''
      };
      if (Array.isArray(this.mapData.center)) {
        lngLat.lng = this.mapData.center[0];
        lngLat.lat = this.mapData.center[1];
      } else {
        if (this.mapData?.center?.lng && this.mapData?.center?.lat) {
          lngLat = { ...this.mapData.center };
        }
      }
      this.mapInfo.lngLat = lngLatFormatter({ lngLat });
    },
    async loadNearbyParcels() {
      const nearbyParcels = await getNearbyParcels(this.parcelsComputed);
      this.$store.commit('setTctParcels', nearbyParcels.tct);
      this.$store.commit('setTotParcels', nearbyParcels.tot);

      const allTotParcels = (await getAllTotParcels()).map(i=>{
        return {
          ...i,
          owner: 'tot'
        }
      }).filter(i=>i?.geojson?.geometry)
      this.$store.commit("setTotParcelsAll", allTotParcels)

      this.renderNearbyParcels(true);
    },
    renderNearbyParcels(remove = false) {
      if (remove) {
        const tct = this.map.findSource('tct-parcels');
        if (tct.source) {
          tct.source.destroy();
        }
        const tot = this.map.findSource('tot-parcels');
        if (tot.source) {
          tot.source.destroy();
        }
      }

      const filters = {
        acresFilter: this.parcelFilters.acresFilter,
        referenceFilter: this.parcelFilters.referenceFilter,
        idFilter: this.parcelFilters.idFilter
      };

      const tct = this.map.findSource('tct-parcels');
      const tot = this.map.findSource('tot-parcels');

      if (tct.source) {
        if (this.mapConfig.tct) {
          tct.source.show();
        } else {
          tct.source.hide();
        }
      } else {
        const tctParcels = this.$store.state.parcel.tctParcels.map(p => {
          return parcelGeoJSON({ parcel: p, ...filters });
        });

        if (this.mapConfig.tct) {
          this.map.add('tct-parcels', 'GEOJSON', {
            geojson: {
              type: 'FeatureCollection',
              features: tctParcels,
              visible: this.mapConfig.tct
            }
          });
        }
      }

      if (tot.source) {
        if (this.mapConfig.tot) {
          tot.source.show();
        } else {
          tot.source.hide();
        }
      } else {
        const totParcelsFactory = (mode) => {
          if(mode==='all'){
            return this.$store.state.parcel.totParcelsAll
          }
          return this.$store.state.parcel.totParcels
        }

        const totParcels = totParcelsFactory(this.mapConfig.totMode).map(p => {
          return parcelGeoJSON({ parcel: p, ...filters });
        });

        if (this.mapConfig.tot) {
          this.map.add('tot-parcels', 'GEOJSON', {
            geojson: {
              type: 'FeatureCollection',
              features: totParcels
            }
          });
        }
      }
    },
    renderQueryLayers() {
      const qls = parseLayers(this.$route.query);
      qls.forEach(layer => {
        this.renderCmsLayer({ key: layer.key, style: layer.style });
      });
    },
    updateMapState({ key, value }) {
      if (!value || !key) {
        return;
      }
      const valueParsed = parseVariable(key, value);
      if (this.mapState[key]) {
        return;
      }
      this.mapState[key] = valueParsed;
      switch (key) {
        case 'zoom':
          this.map.map.setZoom(valueParsed);
          break;
        case 'center':
          this.map.map.setCenter(valueParsed);
          break;
        case 'bearing':
          this.map.map.setBearing(valueParsed);
          break;
        case 'pitch':
          this.map.map.setPitch(valueParsed);
          break;
        case 'style':
          const style = MAP_STYLES[valueParsed];
          if (style) {
            this.mapStyle = style;
          }
          break;
        case 'org':
          const orgs = this.$store.state.organizations;
          if (orgs && Array.isArray(orgs)) {
            const filtered = orgs.filter(
              org =>
                String(org.key).trim().toLowerCase() === String(valueParsed).trim().toLowerCase()
            );
            const orgId = filtered[0]?.id;
            if (orgId) {
              return this.loginAsOrganization(orgId);
            }
          }
          break;
      }
    },
    async renderCmsLayer({ id, key, style, geoscript, forced }) {
      key = String(key || '').trim();
      style = String(style || '').trim();

      if (!Array.isArray(geoscript)) {
        geoscript = [String(geoscript || '').trim()];
      }
      geoscript = geoscript.filter(g => !!g);

      let layerId = id || `${key}${style ? '*' + style : ''}`;
      const cms = new CMS();

      let q = [cms.detail(id, key), cms.detail(null, style)];

      try {
        q = await Promise.all(q.map(p => p.catch(e => e)));
        q = q.filter(result => !(result instanceof Error));
      } catch {}

      let [layerData, geostyleData] = q;
      if (!layerData || !layerData.data || !Object.keys(layerData || {})) {
        return;
      }

      const labelStyles = layerData?.data?.label;
      layerData = { ...layerData?.data, id: layerData.id };
      geostyleData = geostyleData?.data;

      if (layerData.type === 'geoscript') {
        if (this.geoscripts.includes(key) && !forced) {
          return;
        }

        const index = this.geoscripts.indexOf(key);
        if (index !== -1) {
          this.geoscripts.splice(index, 1);
        }

        this.geoscripts.push(key);
        this.loadedGeoscripts[key] = true;
        const parsed = geoscriptMapKeys(layerData.geoscript);
        if (!parsed || !Array.isArray(parsed)) {
          console.warn(`Invalid geoscript ${key}`);
          return;
        }
        parsed.forEach(gs => {
          if (gs.type === 'variable') {
            this.updateMapState(gs);
          } else {
            if (gs.key === key) {
              console.warn(`Infinity loop detected: ${key}`);
              return;
            } else {
              this.renderCmsLayer({
                key: gs.key,
                style: gs.style,
                geoscript: [...geoscript, key]
              });
            }
          }
        });
        return;
      }

      if (!geostyleData) {
        try {
          const tmpGeostyle = await cms.detail(null, layerData.geostyle);
          if (tmpGeostyle.data) {
            geostyleData = tmpGeostyle.data;
          }
        } catch {
          //
        }
      }

      if (!geostyleData) {
        geostyleData = {};
      }

      let geojson;

      const type = layerData.type;
      if (type === 'geojson') {
        if (!geoscript || !geoscript.length) {
          const index = this.styledGeometries.indexOf(layerData.id);
          this.loadedGeometries[layerData.id] = true;
          if (index === -1) {
            this.styledGeometries.push(layerData.id);
          }
          layerId = layerData.id;
        }
        geojson = parseGeoJSON(layerData.geojson);
        if (!geojson) {
          return;
        }
      } else if (type === 'geocircle') {
        geojson = geocircleToGeojson(layerData);
      } else if (type === 'point') {
        const dataParsed = parseGeoPoint(layerData);
        if (layerData.pointType === 'marker') {
          this.map.add(layerId, 'MARKER', {
            geoscript,
            center: dataParsed.coordinates,
            rotation: dataParsed.rotation,
            scale: dataParsed.scale,
            color: dataParsed.color
          });
          return;
        } else if (layerData.pointType === 'point') {
          geojson = dataParsed;
        }
      }

      geostyleData.fillOpacitySatellite = geostyleData?.fillOpacity || 0.5;

      geostyleData = { ...geostyleData, ...labelToGeostyle(labelStyles) };
      geojson = Map.applyGeostyle(geojson, geostyleData);

      geojson.features = geojson.features.map(f => {
        let res = f;
        res.properties.text = layerData?.label?.text || getTextProperty(res.properties) || '';

        const parcel = {
          number: res.properties?.number || res.properties?.parcelNumber || '',
          acres: '',
          reference: layerData?.properties?.reference || ''
        };

        if (layerData?.properties?.areaUnit === 'acre') {
          parcel.acres = layerData?.properties?.area || '';
        } else {
          parcel.acres = parseAcres(layerData?.properties?.area) || '';
        }

        if (res?.properties?.acres) {
          parcel.acres = res.properties.acres;
        }

        const description = getDescription({ parcel, ...this.parcelFilters });
        res.properties.text = res.properties.text + ` \n ${description}`;
        return res;
      });

      this.map.add(layerId, 'GEOJSON', {
        geojson,
        geoscript,
        geostyle: Map.validateGeostyle(geostyleData)
      });
    },

    executeGeoscript(key) {
      if (this.loadedGeoscripts[key]) {
        const sources = this.map.findSources('usedBy', key);
        this.geoscripts.push(key);
        if (sources.length) {
          sources.forEach(source => {
            source.show();
          });
        }
      } else {
        this.renderCmsLayer({ key, geoscript: [key] });
      }
    },

    cancelGeoscript(key) {
      const sources = this.map.findSources('usedBy', key);
      sources.forEach(source => {
        source.hide();
      });
      const index = this.geoscripts.indexOf(key);
      if (index !== -1) {
        this.geoscripts.splice(index, 1);
      }
    },

    onClickParcel(parcel) {
      this.mapMoving = true;
      setTimeout(() => {
        this.mapMoving = false;
      }, 1000);
      if (this.mapData.cursor === 'crosshair') {
        const snackbar = {
          type: 'info',
          message: 'Focus parcel does not work in this cursor mode. Double click to change cursor',
          timeout: 10000
        };
        this.$store.dispatch('showSnackbar', snackbar);
      }
      let point = parcel.center;
      let geojson;
      try {
        geojson = JSON.parse(parcel.geojson);
      } catch {}
      if (!point) {
        try {
          point = center(JSON.parse(parcel.geojson)).geometry.coordinates;
        } catch {}
      }
      if (!point) {
        return;
      }
      this.map.map.flyTo({
        center: point,
        essential: true,
        zoom: 16
      });
      if (geojson) {
        this.map.highlight({ geojson });
      }
    },
    refreshGeoscripts() {
      this.$store.dispatch('fetchLinkedGroups');
      this.geoscripts.forEach(key => {
        this.renderCmsLayer({ key, geoscript: [key], forced: true });
      });
      this.styledGeometries.forEach(id => {
        this.renderCmsLayer({ id, forced: true });
      });
    },
    onMapLoad() {
      this.map.addControls(['GeolocateControl', 'NavigationControl', 'FullscreenControl']);
      if (this.mapConfig.scale) {
        this.map.addControls(['ScaleControl'], 'bottom-right');
      }
      const toggleCircleLayer = throttle(1000, zoom => {
        if (!this.map.map) return;
        if (Number(zoom) > 8) {
          this.map.hideLayer('parcel-circle');
        } else {
          this.map.showLayer('parcel-circle');
        }
      });
      this.map.onUpdateMap(mapInfo => {
        this.mapInfo = mapInfo;
        if (!mapInfo.lngLat) {
          this.setLngLat();
        }
        toggleCircleLayer(mapInfo.zoom);
      });
      this.map.onClick(lngLat => {
        this.$copyText(lngLat);
      });
      this.map.map.on('click', 'parcels-fill', e => {
        const owner = e.features[0].properties.owner;
        if (String(owner).toLowerCase() == 'tct' || String(owner).toLowerCase() == 'tot') {
          return;
        }
        const id = e.features[0].properties.parcelId;
        if (this.cursor !== 'crosshair') {
          this.setParcelDetail('id', id);
          this.setParcelDetail('dialog', true);
        }
      });
      this.renderParcels();
      this.renderTeamGeoJSON();
      this.loadNearbyParcels();
      this.mapHasRendered = true
    },
    setParcelDetail(key, value) {
      this.$store.commit('setParcelDetail', { key, value });
    },
    renderParcels() {
      let parcels = [...(this.parcelsComputed || [])].map(p =>
        parcelGeoJSON({ parcel: p, ...this.parcelFilters })
      );

      parcels = parcels.map(p => {
        p.properties = { ...Map.geostyleToProperties(), ...p.properties };
        p.properties.text = p.properties.description;
        return p;
      });
      const geojson = {
        type: 'FeatureCollection',
        features: parcels
      };

      try {
        this.map.add('parcels', 'GEOJSON', { geojson, hover: true });
        const ct = center(geojson);
        this.map.add('parcel-circle', 'GEOJSON', {
          geojson: Map.applyGeostyle(ct, geostyleParcelPoint),
          visible: false
        });
      } catch {
        //
      }
      this.renderLinkedGroup();
    },
    renderSearchResults(parcels) {
      if (this.mapMoving) return;
      if (!this.map.styleLoaded || !this.map || !this.map.map) return;
      const geojson = {
        type: 'FeatureCollection',
        features: []
      };

      let parcelGeojson = { type: 'FeatureCollection', features: [] };

      parcels.forEach(parcel => {
        const center = parcelCenter(parcel);
        if (center && center?.length === 2) {
          geojson.features.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: center
            },
            properties: {}
          });
        }
        try {
          parcelGeojson.features.push(JSON.parse(parcel.geojson));
        } catch {}
      });

      this.map.add('search-results', 'GEOJSON', { geojson, type: 'marker' });
      try {
        const bb = bbox(parcelGeojson);
        parcelGeojson = null;
        if (bb && bb.every(i => !isNaN(i) && isFinite(i))) {
          this.map.map.fitBounds(bb, { padding: 150 });
        }
      } catch {}
    },
    renderHoverParcel(parcel, state) {
      const id = 'search-parcel-hover';
      if (!state) {
        this.map.removeMarker(id);
        return;
      }
      const center = parcelCenter(parcel);
      if (center && center?.length === 2) {
        this.map.add(id, 'MARKER', { center: center, color: 'red' });
      }
    },
    renderTeamGeoJSON() {
      const showBoundary = this.mapConfig.boundary;
      const lineColor = isSatellite(this.mapConfig.style) ? 'white' : 'rgb(0,0,0,0.6)';
      const boundaryGeoJSON = Map.applyGeostyle(truroBoundary, {
        lineJoin: 'round',
        lineCap: 'round',
        dashArray: '[3,3]',
        dash: 'array',
        lineWidth: 1,
        lineOpacity: 1,
        lineColor
      });
      this.map.add('team-boundary', 'GEOJSON', { geojson: boundaryGeoJSON, visible: showBoundary });
    },
    toggleGeometry(id) {
      const index = this.styledGeometries.indexOf(id);

      if (this.loadedGeometries[id]) {
        const { source } = this.map.findSource(id);
        if (index === -1) {
          this.styledGeometries.push(id);
          source.show();
        } else {
          this.styledGeometries.splice(index, 1);
          source.hide();
        }
      } else {
        if (index !== -1) {
          const { source } = this.map.findSource(id);
          source.hide();
          this.styledGeometries.splice(index, 1);
        } else {
          this.styledGeometries.push(id);
          this.loadedGeometries[id] = true;
          this.renderCmsLayer({ id });
        }
      }
    },
    async renderLinkedGroup() {
      const linkedGeometries = this.$store.state.parcel.linkedGroupsGeometries;
      const { linkedGroupGeometry, linkedGroupGeometryLabel } = this.mapConfig;
      if (
        !linkedGeometries ||
        !Array.isArray(linkedGeometries) ||
        !linkedGroupGeometry ||
        !linkedGroupGeometryLabel
      )
        return;

      const geojson = {
        type: 'FeatureCollection',
        features: []
      };
      let geostyles = [];

      linkedGeometries.forEach(geo => {
        const geometry = parseGeoJSON(geo.data.geojson);
        if (geometry) {
          const parcelNumber = fmtParcelNumber(geo.data.parcel, 'NNN-NNN');
          const parcelFound = this.parcelsComputed.some(
            ({ number }) => fmtParcelNumber(number, 'NNN-NNN') === parcelNumber
          );
          if (parcelFound) {
            geojson.features.push({
              geostyle: geo.data.geostyle,
              label: geo.data.label,
              geometry,
              group: geo.data.group
            });
            if (geo.data.geostyle) {
              geostyles.push(geo.data.geostyle);
            }
          }
        }
      });
      const cms = new CMS();
      geostyles = geostyles.map(i => cms.detail(null, i).catch(err => null));
      let results = await Promise.all(geostyles);
      results = results.filter(i => i);

      const getGeostyle = (items, key) => {
        return items.filter(i => i.data.key === key)[0];
      };

      geojson.features = geojson.features.map(feature => {
        const geostyleData = {
          ...(getGeostyle(results, feature.geostyle)?.data || {}),
          ...labelToGeostyle(feature.label)
        };

        if (isSatellite(this.mapStyle)) {
          geostyleData.textColor = 'white';
        }

        const group = LinkedGroup.group(feature.group);

        if (!(linkedGroupGeometry || []).includes(group)) {
          geostyleData.lineOpacity = 0;
          geostyleData.lineOpacitySatellite = 0;
          geostyleData.fillOpacity = 0;
          geostyleData.fillOpacitySatellite = 0;
        }
        if (!(linkedGroupGeometryLabel || []).includes(group)) {
          geostyleData.textOpacity = 0;
        }

        let res = Map.applyGeostyle(feature.geometry, geostyleData).features[0];
        res.properties.text = feature?.label?.text || getTextProperty(res.properties) || '';
        return res;
      });
      this.map.add('linked-parcels', 'GEOJSON', { geojson });
    }
  }
};
</script>

<style lang="sass" scoped>
.map
  width: 100%
  position: absolute
  height: 100%

.action
  margin-top: 0.5rem
  position: absolute
  z-index: 1
  opacity: 0.5

.map-styles
  position: absolute
  bottom: 0
  left: 0
  z-index: 3
  opacity: 0.5

.rotate-45
  transform: rotate(-45deg)

.hover-image
  border: 2px solid transparent
  border-radius: 6px
  &:hover
    border: 2px solid #1976d2

.img-border
  border: 2px solid #1976d2

.map-style-thumbnail
  height: 45px
  width: 55px

  @media screen and (max-width: 500px)
    height: 35px
    width: 40px

.thumbnail-text
  @media screen and (max-width: 500px)
    font-size: 11px !important

.fixed-bottom-left
  position: fixed
  left: 0
  bottom: 0
</style>
